var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "b-modal",
    {
      attrs: {
        size: "lg",
        centered: "",
        "hide-header": "",
        "hide-footer": "",
        "no-close-on-backdrop": "",
      },
      model: {
        value: _vm.show,
        callback: function ($$v) {
          _vm.show = $$v
        },
        expression: "show",
      },
    },
    [
      _c(
        "h2",
        {
          staticClass: "my-5 main-color",
        },
        [_vm._v("變更密碼")]
      ),
      _c("h5", [_vm._v("歡迎加入wuwow Jr大家庭，請儘速變更密碼")]),
      _c(
        "div",
        {
          staticClass: "form-group mx-auto text-left mt-5",
        },
        [
          _c(
            "p",
            {
              staticClass: "mb-1 mt-3",
            },
            [_vm._v("新密碼")]
          ),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.password,
                expression: "password",
              },
            ],
            staticClass: "form-control",
            attrs: {
              type: "password",
            },
            domProps: {
              value: _vm.password,
            },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.password = $event.target.value
              },
            },
          }),
          _c(
            "P",
            {
              staticClass: "mb-1 mt-3",
            },
            [_vm._v("再次輸入新密碼")]
          ),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.confirmPassword,
                expression: "confirmPassword",
              },
            ],
            staticClass: "form-control",
            attrs: {
              type: "password",
            },
            domProps: {
              value: _vm.confirmPassword,
            },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.confirmPassword = $event.target.value
              },
            },
          }),
          _c(
            "div",
            {
              staticClass: "my-5 d-flex justify-content-center",
            },
            [
              _c(
                "button",
                {
                  staticClass: "w-100 btn btn-outline-primary mx-2",
                  on: {
                    click: _vm.close,
                  },
                },
                [_vm._v("下次再說")]
              ),
              _c(
                "button",
                {
                  staticClass: "w-100 btn btn-primary mx-2",
                  attrs: {
                    disabled: _vm.isDisable,
                  },
                  on: {
                    click: _vm.sendChangePasswordRequest,
                  },
                },
                [_vm._v("完成")]
              ),
            ]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }